import styles from './Slider.module.scss';

import { Fragment } from 'react';

import type typeSlider from '@/utilities/types/Slider';
import Button from '@/components/atoms/Button';
import Typography from '@/components/atoms/Typography';
import ArrowRight from '@/icons/maggie/ArrowRight';

interface SlideCaptionProps {
  caption: typeSlider['slides'][number]['caption'];
}

const SlideCaption = (props: SlideCaptionProps) => {
  const { caption } = props;

  const name = caption?.name ?? null;
  const jobTitle = caption?.jobTitle ?? null;
  const cta = caption?.cta ?? null;

  return (
    <div className={styles.caption}>
      {name && (
        <Typography tag="span" xs={2}>
          {name}
        </Typography>
      )}
      {name && jobTitle && (
        <Typography tag="span" xs={2} className={styles.dot}>
          &#8226;
        </Typography>
      )}
      {jobTitle && (
        <Typography tag="span" xs={2}>
          {jobTitle}
        </Typography>
      )}
      {cta && cta.ctaUrl && cta.ctaText && (
        <>
          &nbsp;
          <Typography tag="span" xs={2}>
            <Fragment>
              {' | '}
              {/* {` (`} */}
              <Button
                href={cta.ctaUrl}
                after={<ArrowRight width={10} height={10} />}
                style="link"
              >
                {cta.ctaText}
              </Button>
              {/* {`)`} */}
            </Fragment>
          </Typography>
        </>
      )}
    </div>
  );
};

export default SlideCaption;
